.compareBtn {
  background: transparent;
  border: none;
  outline: none;
  margin-right: 20px;
  fill: white;
}

.compareBtn:hover {
  cursor: pointer;
}

.compareBtn > span {
  height: 25px;
  display: flex;
  align-items: center;
  color: white;
}
.currentTabButton {
  cursor: pointer;
  color: white;
  font-size: 20px;
  padding: 0.5rem;
  border-bottom: 2px solid white;
}

.tabButton {
  color: white;
  font-size: 20px;
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
}

.tabButton:hover {
  border-bottom: 2px solid rgb(255, 255, 255, 0.4);
}

.vanzariButton:hover {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}