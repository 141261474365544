.contactForm {
  width: 80%;
  min-width: 100px;
  max-width: 600px;
  min-height: 200px;
  padding: 40px;
  margin: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.btn {
  background-color: #047efd;
  fill: white;
  color: white;
  width: 100px;
  height: 40px;
}
