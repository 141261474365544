.svgWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
}

.svgWrapper > div:hover {
  cursor: pointer;
}

.boxStyles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: "translate(-50%; -50%)";
  background-color: white;
  border: 2px solid lightgrey;
  outline: none;
  border-radius: 8;
  box-shadow: 24;
}

.miniChartContainer {
  height: auto;
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px !important;
  border: 2px solid transparent;
}

.selected {
  border: 2px solid #047efd;
}

.largeChartCard {
  height: auto;
  width: 100%;
  margin: 12px;
  padding: 20px;
  border-radius: 8px !important;
}

.chartsListContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1536px;
  align-self: center;
}

.artistName {
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 500;
  color: rgb(66, 66, 66);
  text-align: center;
}

.rightButtons {
  display: flex;
  justify-content: flex-end;
}

.icons {
  border-radius: 300px !important;
  width: fit-content;
}

.icons svg {
  fill: rgb(66, 66, 66);
}
