.landingPageContainer {
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.titleContainer {
  width: 60%;
}

.titleOne {
  font-size: calc(50% + 1.5vw);
  font-family: "Rubik";
  margin: 12px;
  font-weight: 800;
  color: #017efd;
}

.titleTwo {
  font-size: calc(50% + 1.2vw);
  font-family: "Rubik";
  margin: 12px;
  color: #017efd;
}

.openingStatement {
  width: 80%;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 700px) {
  .openingStatement {
    width: 100%;
  }
}
