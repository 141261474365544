.footerContainer {
  position: sticky;
  top: 100vh;
  margin-top: 1rem;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 1rem;
}

.links {
  text-decoration-color: black !important;
  color: black !important;
}

.links:hover {
  color: grey !important;
  text-decoration-color: grey !important;
  cursor: pointer;
}
