.shareIcon {
  display: flex;
  justify-content: center;
}

.shareIcon svg {
  display: flex;
  align-items: center;
  margin-left: 5px;
  fill: black;
}

.my-social-icon {
  padding: 10px;
}

.my-social-icon circle {
  fill: #848484;
}

.my-social-icon:hover circle {
  fill: #2e2f30;
}

.input {
  width: 80%;
}

.copyLink {
  display: flex;
  align-items: center;
  padding: 5px;
}

.copyLink svg {
  fill: #848484;
}

.copyLink:hover svg {
  fill: #2e2f30;
  cursor: pointer;
}

.copiedLink {
  display: flex;
  align-items: center;
  padding: 5px;
}

.copiedLink svg {
  fill: #5eb760;
}

.copiedLink:hover svg {
  cursor: pointer;
}
