.btnWrapper {
  display: flex;
  justify-content: flex-end;
  width: 175px;
}

.closeBtn {
  background: transparent;
  border: none;
}

.closeSvg {
  fill: #848484;
  transform-origin: 50% 50%;
  transform: scale(1.2);
}

.closeSvg:hover {
  cursor: pointer;
  fill: #2e2f30;
}
