.headerRow {
  background-color: #067efd;
}

.headerRow th {
  color: white;
  font-size: 16px;
}

.artistCell {
  font-weight: bold !important;
  font-size: 16px !important;
}

.leadingCell {
  background-color: rgb(6, 126, 253, 0.2);
  font-weight: bold !important;
}

.sectionTitle {
  width: -webkit-fill-available;
  border-bottom: 1px solid grey;
  margin: 0px 20px;
  text-transform: uppercase;
  padding: 5px 0;
  color: #424242;
}
