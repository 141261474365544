.legendItem {
  width: 80%;
  max-width: 800px;
  padding: 25px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendItem h5 {
  display: flex;
}

.legendItem span {
  font-size: 16px;
  display: flex;
}

.content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
}

.content svg {
  transform: scale(5);
}

.image {
  height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
}
