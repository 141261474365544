.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px 5px 5px 10px;
  gap: 1rem;
  background-color: white;
}

.searchContainer:hover {
  cursor: text;
  border: 1px solid grey;
}

.selected {
  border: 1px solid #047efd;
}

.selected:hover {
  border: 1px solid #047efd;
}

.input {
  border: none;
  background-color: white;
  font-family: Roboto;
  font-size: 20px;
  width: 150px !important;
}

.input:focus {
  outline: none;
}

@media only screen and (max-width: 700px) {
  .searchContainer {
    padding: 0 10px;
  }
}
